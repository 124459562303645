import { ToastProgrammatic as Toast } from 'buefy'

export interface IAppNotification {
  content: string
  position?: 'is-top-right' | 'is-top' | 'is-top-left' | 'is-bottom-right' | 'is-bottom' | 'is-bottom-left' | undefined
  duration?: number | undefined
  showProgress?: boolean
}

function showToast(
  notification: IAppNotification,
  type?:
    | 'is-dark'
    | 'is-white'
    | 'is-black'
    | 'is-light'
    | 'is-dark'
    | 'is-primary'
    | 'is-info'
    | 'is-success'
    | 'is-warning'
    | 'is-danger'
    | undefined
) {
  Toast.open({
    type: type,
    message: notification.content,
    position: notification.position ? notification.position : 'is-top',
    duration: notification.duration,
    queue: false,
  })
}

export const showNotification = (notification: IAppNotification) => showToast(notification)
export const showSuccessNotification = (notification: IAppNotification) => showToast(notification, 'is-success')
export const showInfoNotification = (notification: IAppNotification) => showToast(notification, 'is-info')
export const showErrorNotification = (notification: IAppNotification) => showToast(notification, 'is-danger')
