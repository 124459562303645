









































import { Component, Vue } from 'vue-property-decorator'
import { UserStore } from '@/store/modules/user'
import { AppStore } from '@/store/modules/app'

@Component
export default class NavBar extends Vue {
  // Methods
  showAbout() {
    AppStore.SET_IS_ABOUT_ACTIVE(true)
  }
  async logOut() {
    UserStore.actionUserLogOut()
  }
}
