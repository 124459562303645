import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { loginApi } from '@/store/api/public/login'
import { profileApi } from '@/store/api/public/profile'
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils/local-storage'
import { AxiosError } from 'axios'
import router from '@/router'
import { showInfoNotification, showSuccessNotification } from '@/utils/notification'
import { AppStore } from './app'
import { ErrorCode, checkApiError } from '../api/error-handlers'
import { ExamStore } from './exam'
import { ReportStore } from './report'
import { IPatient } from '../interfaces/patient-model'

export interface IUserState {
  token: string
  patientProfile: IPatient | null
  isLoggedIn: boolean | null
  loginError: boolean
}

@Module({ dynamic: true, store, name: 'user', namespaced: true })
class User extends VuexModule implements IUserState {
  token = ''
  patientProfile: IPatient | null = null
  isLoggedIn: boolean | null = null
  loginError = false

  @Mutation
  RESET_STATE() {
    this.token = ''
    this.patientProfile = null
    this.isLoggedIn = null
    this.loginError = false
  }

  @Mutation
  private SET_TOKEN(payload: string) {
    this.token = payload
  }

  @Mutation
  private SET_PATIENT_PROFILE(payload: IPatient) {
    this.patientProfile = payload
  }

  @Mutation
  private SET_LOGGED_IN(payload: boolean) {
    this.isLoggedIn = payload
  }

  @Mutation
  private SET_LOGIN_ERROR(payload: boolean) {
    this.loginError = payload
  }

  @Action
  async actionLogIn(payload: { username: string; password: string }): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      const response = await loginApi.loginGetToken(payload.username, payload.password)
      const token = response.data.access_token
      if (token) {
        saveLocalToken(token)
        this.SET_TOKEN(token)
        this.SET_LOGGED_IN(true)
        this.SET_LOGIN_ERROR(false)
        await this.actionGetPatientProfile()
        this.actionRouteLoggedIn()
        showSuccessNotification({ content: 'Bentornato!' })
      } else {
        await this.actionLogOut()
      }
    } catch (error) {
      this.SET_LOGIN_ERROR(true)
      await this.actionCheckApiError(error)
      await this.actionLogOut()
    } finally {
      AppStore.SET_IS_LOADING(false)
    }
  }

  @Action
  async actionGetPatientProfile(): Promise<boolean> {
    try {
      const response = await profileApi.getMe(this.token)
      if (response.data) {
        this.SET_PATIENT_PROFILE(response.data)
      }
      return true
    } catch (error) {
      await this.actionCheckApiError(error)
      return false
    }
  }

  @Action
  async actionCheckLoggedIn(): Promise<void> {
    if (!this.isLoggedIn) {
      let token = this.token
      if (!token) {
        const localToken = getLocalToken()
        if (localToken) {
          this.SET_TOKEN(localToken)
          token = localToken
        }
      }
      if (token) {
        const result = await this.actionGetPatientProfile()
        if (result) {
          this.SET_LOGGED_IN(true)
        } else {
          await this.actionRemoveLogIn()
        }
      } else {
        await this.actionRemoveLogIn()
      }
    }
  }

  @Action
  async actionRemoveLogIn(): Promise<void> {
    removeLocalToken()
    this.SET_TOKEN('')
    this.SET_LOGGED_IN(false)
  }

  @Action
  async actionLogOut(): Promise<void> {
    await this.actionRemoveLogIn()
    this.actionRouteLogOut()
  }

  @Action
  async actionUserLogOut(): Promise<void> {
    await this.actionLogOut()
    showInfoNotification({ content: 'Disconnesso. Arrivederci!' })
  }

  @Action
  actionRouteLogOut(): void {
    if (router.currentRoute.path !== '/login') {
      router.push('/login')
    }
    this.actionResetAllModuleStates()
  }

  @Action
  actionRouteLoggedIn(): void {
    if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
      router.push('/main/dashboard')
    }
  }

  @Action
  async actionPasswordRecovery(email: string): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([loginApi.passwordRecovery(email), await new Promise((resolve) => setTimeout(resolve, 500))])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({
        content: 'Una mail contenente le istruzioni per ripristinare la password è stata inviata.',
      })
      await this.actionLogOut()
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      await this.actionCheckApiError(error)
    }
  }

  @Action
  async actionResetPassword(payload: { token: string; password: string }): Promise<void> {
    try {
      AppStore.SET_IS_LOADING(true)
      await Promise.all([
        loginApi.resetPassword(payload.token, payload.password),
        await new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      AppStore.SET_IS_LOADING(false)
      showSuccessNotification({ content: 'Password resettata con successo' })
      await this.actionLogOut()
    } catch (error) {
      AppStore.SET_IS_LOADING(false)
      await this.actionCheckApiError(error)
    }
  }

  @Action
  async actionCheckApiError(error: AxiosError): Promise<ErrorCode> {
    const errorCode = checkApiError(error)
    if (errorCode == ErrorCode.UNAUTHORIZED_ERROR) {
      await this.actionLogOut()
    }
    return errorCode
  }

  @Action
  actionResetAllModuleStates() {
    AppStore.RESET_STATE()
    ExamStore.RESET_STATE()
    ReportStore.RESET_STATE()
  }
}

export const UserStore = getModule(User)
