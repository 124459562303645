import { AxiosResponse } from 'axios'

export function saveBlobResponseAsFile(response: AxiosResponse<any>, defaultFileName: string) {
  if (response) {
    let fileName = defaultFileName
    const contentDisposition = response.headers['content-disposition']
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) fileName = fileNameMatch[1]
    }
    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    link.click()
    URL.revokeObjectURL(link.href)
  }
}
