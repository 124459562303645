import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { IExam, IExamWithReports } from '@/store/interfaces/exam-model'

export const examApi = {
  async getAll(token: string) {
    return axios.get<IExamWithReports[]>(`${apiUrl}/v1/public/exams/`, authConfig(token))
  },
  async get(token: string, examId: number) {
    return axios.get<IExam>(`${apiUrl}/v1/public/exams/${examId}`, authConfig(token))
  },
  async delete(token: string, examId: number) {
    return axios.delete<IExam>(`${apiUrl}/v1/public/exams/${examId}`, authConfig(token))
  },
}
