import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'
import '@/style/scss/main.scss'
import '@mdi/font/css/materialdesignicons.min.css'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

Vue.use(Buefy)
Vue.config.productionTip = false

Vue.filter('formatDate', (value: string) => {
  if (value) {
    return dayjs(String(value)).format('DD/MM/YYYY')
  } else {
    return 'n.d.'
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
