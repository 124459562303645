import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig, blobConfig } from '@/store/api/configs'

export const reportApi = {
  async delete(token: string, reportId: number) {
    return axios.delete(`${apiUrl}/v1/public/reports/${reportId}`, authConfig(token))
  },
  async download(token: string, reportId: number) {
    return axios.get(`${apiUrl}/v1/public/reports/download/${reportId}`, blobConfig(token))
  },
}
