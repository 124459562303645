import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig, blobConfig } from '@/store/api/configs'
import { IPrescription } from '@/store/interfaces/prescription-model'

export const prescriptionApi = {
  async getAll(token: string) {
    return axios.get<IPrescription[]>(`${apiUrl}/v1/public/prescriptions/`, authConfig(token))
  },
  async delete(token: string, prescriptionId: number) {
    return axios.delete(`${apiUrl}/v1/public/prescriptions/${prescriptionId}`, authConfig(token))
  },
  async download(token: string, prescriptionId: number) {
    return axios.get(`${apiUrl}/v1/public/prescriptions/download/${prescriptionId}`, blobConfig(token))
  },
}
