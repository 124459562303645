import store from '@/store'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import { IPrescription } from '../interfaces/prescription-model'
import { prescriptionApi } from '../api/public/prescriptions'
import { UserStore } from './user'
import { saveBlobResponseAsFile } from '@/utils/file'
import { checkApiError } from '../api/error-handlers'
import NProgress from 'nprogress'
import { showSuccessNotification } from '@/utils/notification'
import { ExamStore } from './exam'

export interface IPrescriptionState {
  data: IPrescription[]
  current: IPrescription | null
}

@Module({ dynamic: true, store, name: 'prescription', namespaced: true })
class Prescription extends VuexModule implements IPrescriptionState {
  data: IPrescription[] = []
  current: IPrescription | null = null

  @Mutation
  RESET_STATE() {
    this.data = []
    this.current = null
  }

  @Mutation
  private SET_DATA(payload: IPrescription[]) {
    this.data = payload
  }

  @Action
  async actionGetAll(): Promise<boolean> {
    try {
      const response = await prescriptionApi.getAll(UserStore.token)
      if (response) {
        this.SET_DATA(response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionDelete(id: number): Promise<boolean> {
    try {
      NProgress.start()
      await Promise.all([
        prescriptionApi.delete(UserStore.token, id),
        await new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      NProgress.done()
      ExamStore.DELETE_EXAM_REPORT(id)
      showSuccessNotification({ content: 'Ricetta rimossa correttamente.' })
      return true
    } catch (error) {
      NProgress.done()
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionDownload(id: number): Promise<void> {
    try {
      NProgress.start()
      const response = await prescriptionApi.download(UserStore.token, id)
      NProgress.inc()
      saveBlobResponseAsFile(response, 'ricetta.pdf')
      NProgress.done()
    } catch (error) {
      NProgress.done()
      checkApiError(error)
    }
  }
}

export const PrescriptionStore = getModule(Prescription)
