import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'
import { IPatient } from '@/store/interfaces/patient-model'

export const profileApi = {
  async getMe(token: string) {
    return axios.get<IPatient>(`${apiUrl}/v1/public/profile/`, authConfig(token))
  },
}
