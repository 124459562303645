import { AxiosError, AxiosResponse } from 'axios'
import { showErrorNotification } from '@/utils/notification'
import { UserStore } from '../modules/user'

export enum ErrorCode {
  // Generic
  UNEXPECTED_ERROR = -1,
  UNKNOWN_ERROR = 1,
  GENERIC_ERROR,
  NOT_FOUND_ERROR,
  SERVER_ERROR,
  NETWORK_ERROR,
  UNAUTHORIZED_ERROR,
  FORBIDDEN_ERROR,
  BAD_REQUEST_ERROR,
  MAINTENANCE_ERROR,
  REQUEST_TOO_LARGE,

  // Authentication
  AUTH_BAD_TOKEN = 201,
  AUTH_TOKEN_EXPIRED,
  AUTH_FORBIDDEN,
  AUTH_USER_UNBOUND,
  AUTH_CHANGE_PASSWORD,
  AUTH_INACTIVE_USER,
  AUTH_INACTIVE_PATIENT,
  AUTH_BAD_SECURE_RANDOM,

  // Login
  LOGIN_WRONG_EMAIL_OR_PASSWORD = 11,
  LOGIN_INACTIVE_USER,
  LOGIN_INVALID_TOKEN,
  LOGIN_USER_NOT_FOUND,
  LOGIN_INACTIVE_PATIENT,
  LOGIN_PATIENT_NOT_FOUND,
  LOGIN_WRONG_USERNAME_OR_PASSWORD,
  LOGIN_EMAIL_NOT_VERIFIED,

  // Company
  COMPANY_EXISTING_EMAIL = 21,
  COMPANY_NOT_FOUND,
  COMPANY_ALREADY_COMPLETED,

  // User
  USER_EXISTING_EMAIL = 31,
  USER_NOT_FOUND,
  USER_PWD_UPDATE_ERROR,
  SUPERUSER_NOT_FOUND,
  SUPERUSER_NOT_ENOUGH,

  // Patient
  PATIENT_EXISTING_EMAIL = 41,
  PATIENT_EXISTING_FISCAL_CODE,
  PATIENT_NOT_FOUND,

  // Exam Type
  EXAM_TYPE_EXISTING_NAME = 51,
  EXAM_TYPE_NOT_FOUND,

  // Exam
  EXAM_PATIENT_ID_NOT_FOUND = 61,
  EXAM_EXAM_TYPE_ID_NOT_FOUND,
  EXAM_NOT_FOUND,
  EXAM_EXISTING_REF,

  // Operator
  OPERATOR_EXISTING_EMAIL = 71,
  OPERATOR_NOT_FOUND,

  // Report
  REPORT_EXAM_ID_NOT_FOUND = 81,
  REPORT_FILE_NOT_UPLOADED,
  REPORT_NOT_FOUND,
  REPORT_DOWNLOAD_ERROR,
  REPORT_MERGE_ERROR,
  REPORT_FILES_LIMIT,

  // Upload
  UPLOAD_INVALID_TYPE = 91,
  UPLOAD_INVALID_PDF,
  UPLOAD_SAVING_ERROR,

  // Prescription
  PRESCRIPTION_PATIENT_ID_NOT_FOUND = 101,
  PRESCRIPTION_NOT_FOUND,
  PRESCRIPTION_FILE_NOT_UPLOADED,
  PRESCRIPTION_FILE_SAVING_ERROR,
  PRESCRIPTION_DOWNLOAD_ERROR,

  // Billing
  BILLING_CREATE_CHECKOUT_SUPERUSER_FORBIDDEN = 111,
  BILLING_GET_CUSTOMER_PORTAL_SUPERUSER_FORBIDDEN,
  BILLING_CREATE_CHECKOUT_ERROR,
  BILLING_GET_CHECKOUT_SESSION_ERROR,
  BILLING_GET_CUSTOMER_PORTAL_ERROR,
  BILLING_TOO_MUCH_OPERATORS,
  BILLING_TOO_MUCH_EXAMS,
  BILLING_PLAN_NOT_AVAILABLE,
  BILLING_ALREADY_SUBSCRIBED,
  BILLING_INFO_NOT_COMPLETED,

  // Web
  WEB_EXISTING_EMAIL = 131,
  WEB_EMAIL_VERIFICATION_ERROR,

  // Others
  TOO_SHORT_SEARCH_STRING = 900,
  FRAUDULENT_INTERACTION,
}

export const ErrorMessage = {
  // Generic
  [ErrorCode.UNEXPECTED_ERROR]: "Si è verificato un errore imprevisto. Riprovare o contattare l'amministratore.",
  [ErrorCode.UNKNOWN_ERROR]: 'Errore sconosciuto. Riprovare.',
  [ErrorCode.GENERIC_ERROR]: 'Si è verificato un errore. Riprovare.',
  [ErrorCode.NOT_FOUND_ERROR]: 'Risorsa non disponibile.',
  [ErrorCode.SERVER_ERROR]: 'Errore interno del server. Riprovare più tardi.',
  [ErrorCode.NETWORK_ERROR]: 'Errore di rete: impossibile contattare il server. Riprovare più tardi.',
  [ErrorCode.UNAUTHORIZED_ERROR]: 'Accesso non autorizzato.',
  [ErrorCode.FORBIDDEN_ERROR]: 'Privilegi insufficienti per procedere.',
  [ErrorCode.BAD_REQUEST_ERROR]: 'Formato richiesta errato.',
  [ErrorCode.MAINTENANCE_ERROR]: 'Referto In Cloud non è momentaneamente disponibile. Riprovare più tardi. Grazie.',
  [ErrorCode.REQUEST_TOO_LARGE]: 'Dimensione massima superata (Max 10MB).',

  // Authentication
  [ErrorCode.AUTH_BAD_TOKEN]: 'Token non valido.',
  [ErrorCode.AUTH_TOKEN_EXPIRED]: "Connessione scaduta. Si prega di effettuare nuovamente l'accesso.",
  [ErrorCode.AUTH_FORBIDDEN]: 'Non si dispone dei privilegi necessari.',
  [ErrorCode.AUTH_USER_UNBOUND]: "L'utente corrente non è associato a nessuna azienda.",
  [ErrorCode.AUTH_CHANGE_PASSWORD]: 'È necessario cambiare la password prima di proseguire.',
  [ErrorCode.AUTH_INACTIVE_USER]: 'Utente non attivo.',
  [ErrorCode.AUTH_INACTIVE_PATIENT]: 'Paziente non attivo.',
  [ErrorCode.AUTH_BAD_SECURE_RANDOM]: "Disconnesso! Si prega di effettuare nuovamente l'accesso.",

  // Login
  [ErrorCode.LOGIN_WRONG_EMAIL_OR_PASSWORD]: 'Email e/o password errata.',
  [ErrorCode.LOGIN_INACTIVE_USER]: 'Utente non attivo.',
  [ErrorCode.LOGIN_INVALID_TOKEN]:
    "Errore durante il reset della password. Contattare l'assistenza se il problema dovesse persistere.",
  [ErrorCode.LOGIN_USER_NOT_FOUND]: 'Utente non disponibile.',
  [ErrorCode.LOGIN_INACTIVE_PATIENT]: 'Paziente non attivo.',
  [ErrorCode.LOGIN_PATIENT_NOT_FOUND]: 'Paziente non disponibile.',
  [ErrorCode.LOGIN_WRONG_USERNAME_OR_PASSWORD]: 'Username e/o password errata.',
  [ErrorCode.LOGIN_EMAIL_NOT_VERIFIED]:
    'Occorre verificare il tuo indirizzo email. Una mail, contenente le istruzioni per validarlo, ti è stata recapitata in fase di registrazione.',

  // Company
  [ErrorCode.COMPANY_EXISTING_EMAIL]: 'Un azienda con lo stesso indirizzo email è gia presente nel sistema.',
  [ErrorCode.COMPANY_NOT_FOUND]: 'Azienda non disponibile.',
  [ErrorCode.COMPANY_ALREADY_COMPLETED]: 'I dati aziendali sono già stati precedentemente inizializzati.',

  // User
  [ErrorCode.USER_EXISTING_EMAIL]: 'Un utente con lo stesso indirizzo email è gia presente nel sistema.',
  [ErrorCode.USER_NOT_FOUND]: 'Utente non disponibile.',
  [ErrorCode.USER_PWD_UPDATE_ERROR]:
    'Errore durante la modifica della password. Verificare i dati inseriti e riprovare.',
  [ErrorCode.SUPERUSER_NOT_FOUND]: 'Super Utente non disponibile.',
  [ErrorCode.SUPERUSER_NOT_ENOUGH]: "Non è possibile eliminare l'unico Super Utente del sistema.",

  // Patient
  [ErrorCode.PATIENT_EXISTING_EMAIL]: 'Un paziente con lo stesso indirizzo email è gia presente nel sistema.',
  [ErrorCode.PATIENT_EXISTING_FISCAL_CODE]: 'Un paziente con lo stesso codice fiscale è gia presente nel sistema.',
  [ErrorCode.PATIENT_NOT_FOUND]: 'Paziente non disponibile.',

  // Exam Type
  [ErrorCode.EXAM_TYPE_EXISTING_NAME]: "Una tipologia d'esame con lo stesso nome è gia presente nel sistema.",
  [ErrorCode.EXAM_TYPE_NOT_FOUND]: "Tipologia d'esame non disponibile.",

  // Exam
  [ErrorCode.EXAM_PATIENT_ID_NOT_FOUND]: 'Il paziente con id specificato non è stato trovato nel sistema.',
  [ErrorCode.EXAM_EXAM_TYPE_ID_NOT_FOUND]: "La tipologia d'esame con id specificato non è stata trovata nel sistema.",
  [ErrorCode.EXAM_NOT_FOUND]: 'Esame non disponibile.',
  [ErrorCode.EXAM_EXISTING_REF]: 'Un esame con lo stesso codice di riferimento è gia presente nel sistema.',

  // Operator
  [ErrorCode.OPERATOR_EXISTING_EMAIL]: 'Un operatore con lo stesso indirizzo email è gia presente nel sistema.',
  [ErrorCode.OPERATOR_NOT_FOUND]: 'Operatore non disponibile.',

  // Report
  [ErrorCode.REPORT_EXAM_ID_NOT_FOUND]: "L'esame con id specificato non è stato trovato nel sistema.",
  [ErrorCode.REPORT_FILE_NOT_UPLOADED]: 'Il/i file specificati non è/sono stato/i caricato/i nel sistema.',
  [ErrorCode.REPORT_NOT_FOUND]: 'Report non disponibile.',
  [ErrorCode.REPORT_DOWNLOAD_ERROR]:
    'Si è verificato un errore durante il download del referto. Si prega di riprovare.',
  [ErrorCode.REPORT_MERGE_ERROR]:
    'Si è verificato un errore durante il merge dei PDF. Uno o più file potrebbero essere corrotti. Si prega di riprovare o in alternativa è possibile caricare i file singolarmente in più referti.',
  [ErrorCode.REPORT_FILES_LIMIT]: "Non è possibile caricare più di 10 file all'interno di un singolo referto.",

  // Upload
  [ErrorCode.UPLOAD_INVALID_TYPE]: 'Tipologia di upload non valida.',
  [ErrorCode.UPLOAD_INVALID_PDF]: 'File PDF non valido.',
  [ErrorCode.UPLOAD_SAVING_ERROR]: 'Errore durante il salvataggio del file.',

  // Prescription
  [ErrorCode.PRESCRIPTION_PATIENT_ID_NOT_FOUND]: 'Il paziente con id specificato non è stato trovato nel sistema.',
  [ErrorCode.PRESCRIPTION_NOT_FOUND]: 'Ricetta non disponibile.',
  [ErrorCode.PRESCRIPTION_FILE_NOT_UPLOADED]: 'Il file specificato non è stato caricato nel sistema.',
  [ErrorCode.PRESCRIPTION_FILE_SAVING_ERROR]: 'Errore durante il salvataggio della ricetta. Riprovare.',
  [ErrorCode.PRESCRIPTION_DOWNLOAD_ERROR]:
    'Si è verificato un errore durante il download della ricetta. Si prega di riprovare.',

  // Billing
  [ErrorCode.BILLING_CREATE_CHECKOUT_SUPERUSER_FORBIDDEN]:
    "Impossibile creare una sessione di pagamento per conto di un'azienda cliente.",
  [ErrorCode.BILLING_GET_CUSTOMER_PORTAL_SUPERUSER_FORBIDDEN]:
    "Impossibile aprire il portale clienti per conto di un'azienda cliente.",
  [ErrorCode.BILLING_CREATE_CHECKOUT_ERROR]: 'Impossibile procedere col pagamento, si prega di riprovare più tardi.',
  [ErrorCode.BILLING_GET_CHECKOUT_SESSION_ERROR]:
    'Impossibile recuperare la sessione di pagamento, si prega di riprovare più tardi.',
  [ErrorCode.BILLING_GET_CUSTOMER_PORTAL_ERROR]:
    'Non è stato possibile aprire il portale clienti, si prega di riprovare più tardi.',
  [ErrorCode.BILLING_TOO_MUCH_OPERATORS]:
    'Impossibile creare un nuovo operatore: hai raggiunto il limite di operatori previsti dal tuo abbonamento!',
  [ErrorCode.BILLING_TOO_MUCH_EXAMS]:
    'Impossibile inserire un nuovo esame: hai raggiunto il limite mensile di esami previsti dal tuo abbonamento!',
  [ErrorCode.BILLING_PLAN_NOT_AVAILABLE]: 'Attenzione, il piano selezionato non è disponibile.',
  [ErrorCode.BILLING_ALREADY_SUBSCRIBED]: 'Errore: già si dispone di un abbonamento attivo!',
  [ErrorCode.BILLING_INFO_NOT_COMPLETED]:
    'Attenzione, è necessario completare i dati di fatturazione prima di procedere alla sottoscrizione di un abbonamento!',

  // Web
  [ErrorCode.WEB_EXISTING_EMAIL]:
    "L'indirizzo email fornito non è disponibile in quanto già registrato sui nostri sistemi.",
  [ErrorCode.WEB_EMAIL_VERIFICATION_ERROR]:
    "Non è stato possibile verificare l'indirizzo email fornito a causa di un errore.",

  // Others
  [ErrorCode.TOO_SHORT_SEARCH_STRING]: 'Inserire una stringa di ricerca contenente almeno tre caratteri.',
  [ErrorCode.FRAUDULENT_INTERACTION]:
    "Attenzione, è stata rilevata un'interazione fraudolenta. Se il problema dovesse persistere contattare il supporto tecnico.",
}

function getErrorCode(response: AxiosResponse): ErrorCode {
  if (response.headers['content-type'] == 'application/json' && 'detail' in response.data) {
    const code = response.data['detail'][0]
    if (!isNaN(code) && code in ErrorCode) {
      return code as ErrorCode
    }
  }

  switch (response.status) {
    case 400:
      return ErrorCode.BAD_REQUEST_ERROR
    case 401:
      return ErrorCode.UNAUTHORIZED_ERROR
    case 403:
      return ErrorCode.FORBIDDEN_ERROR
    case 404:
      return ErrorCode.NOT_FOUND_ERROR
    case 413:
      return ErrorCode.REQUEST_TOO_LARGE
    case 500:
      return ErrorCode.SERVER_ERROR
    case 502:
    case 504:
      return ErrorCode.MAINTENANCE_ERROR
    default:
      return ErrorCode.GENERIC_ERROR
  }
}

function checkForActionOnError(errorCode: ErrorCode) {
  switch (errorCode) {
    case ErrorCode.UNAUTHORIZED_ERROR:
    case ErrorCode.AUTH_BAD_TOKEN:
    case ErrorCode.AUTH_BAD_SECURE_RANDOM:
    case ErrorCode.AUTH_TOKEN_EXPIRED:
    case ErrorCode.MAINTENANCE_ERROR:
      UserStore.actionLogOut()
      break
    default:
      break
  }
}

export function checkApiError(error: AxiosError, notify = true): ErrorCode {
  if (error.response) {
    const errorCode = getErrorCode(error.response)
    checkForActionOnError(errorCode)
    if (notify) showErrorNotification({ content: ErrorMessage[errorCode] })
    return errorCode
  } else if (error.request) {
    showErrorNotification({ content: ErrorMessage[ErrorCode.NETWORK_ERROR] })
    return ErrorCode.NETWORK_ERROR
  } else {
    showErrorNotification({ content: ErrorMessage[ErrorCode.UNEXPECTED_ERROR] })
    return ErrorCode.UNEXPECTED_ERROR
  }
}

export class NotImplementedError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'NotImplementedError'
  }
}
