





























import { AppStore } from '@/store/modules/app'
import { UserStore } from '@/store/modules/user'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
  // Data
  username = ''
  password = ''

  // Computed Data
  get loginError() {
    return UserStore.loginError
  }
  get isLoading() {
    return AppStore.isLoading
  }

  // Methods
  async onSubmit() {
    await UserStore.actionLogIn({ username: this.username, password: this.password })
    if (this.loginError) this.password = ''
  }
}
