import axios from 'axios'
import { apiUrl } from '@/env'
import { authConfig } from '@/store/api/configs'

export const loginApi = {
  async loginGetToken(username: string, password: string) {
    const params = new URLSearchParams()
    params.append('username', username)
    params.append('password', password)

    return axios.post(`${apiUrl}/v1/public/login/access-token`, params)
  },
  async loginTestToken(token: string) {
    return axios.post(`${apiUrl}/v1/public/login/test-token`, null, authConfig(token))
  },
  async passwordRecovery(username: string) {
    return axios.post(`${apiUrl}/v1/public/password-recovery/${username}`)
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/v1/public/reset-password/`, {
      new_password: password,
      token,
    })
  },
}
