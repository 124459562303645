import { RouteConfig } from 'vue-router'
import RouteNames from './route-names'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: RouteNames.login,
    component: Login,
  },
  {
    path: '/main/home',
    name: RouteNames.home,
    component: Home,
  },
  {
    path: '/*',
    redirect: '/main/home',
  },
]

export default routes
