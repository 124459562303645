






















































































































































































import { Component, Vue } from 'vue-property-decorator'
import NavBar from '@/components/NavBar.vue'
import FooterBar from '@/components/FooterBar.vue'
import AboutModal from '@/components/AboutModal.vue'
import { UserStore } from '@/store/modules/user'
import { ExamStore } from '@/store/modules/exam'
import { ReportStore } from '@/store/modules/report'
import { PrescriptionStore } from '@/store/modules/prescription'

@Component({
  components: {
    NavBar,
    FooterBar,
    AboutModal,
  },
})
export default class Home extends Vue {
  // Data
  isExamsLoading = false
  isPrescriptionsLoading = false

  // Computed Data
  get patientName() {
    if (!UserStore.patientProfile) return ''
    return UserStore.patientProfile?.name + ' ' + UserStore.patientProfile.surname
  }
  get exams() {
    return ExamStore.data
  }
  get openedDetailed() {
    const opened = []
    for (const exam of this.exams) {
      opened.push(exam.id)
    }
    return opened
  }
  get prescriptions() {
    return PrescriptionStore.data
  }

  // Vue Hooks
  mounted() {
    this.loadExams()
    this.loadPrescriptions()
  }

  // Methods
  async loadExams() {
    this.isExamsLoading = true
    await ExamStore.actionGetAll()
    this.isExamsLoading = false
  }
  async loadPrescriptions() {
    this.isPrescriptionsLoading = true
    await PrescriptionStore.actionGetAll()
    this.isPrescriptionsLoading = false
  }
  async downloadReport(id: number) {
    await ReportStore.actionDownload(id)
  }
  confirmReportDelete(id: number) {
    this.$buefy.dialog.confirm({
      title: 'Eliminare il referto?',
      message:
        'Sei sicuro di voler <b>eliminare</b> questo referto?<br>Una volta eliminato non sarà più disponibile per il download.',
      confirmText: 'Elimina',
      cancelText: 'Annulla',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteReport(id),
    })
  }
  async deleteReport(id: number) {
    await ReportStore.actionDelete(id)
  }
  async downloadPrescription(id: number) {
    await PrescriptionStore.actionDownload(id)
  }
  confirmPrescriptionDelete(id: number) {
    this.$buefy.dialog.confirm({
      title: 'Eliminare la ricetta?',
      message:
        'Sei sicuro di voler <b>eliminare</b> questa ricetta?<br>Una volta eliminata non sarà più disponibile per il download.',
      confirmText: 'Elimina',
      cancelText: 'Annulla',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deletePrescription(id),
    })
  }
  async deletePrescription(id: number) {
    await PrescriptionStore.actionDelete(id)
  }
}
