import store from '@/store'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import { IReport } from '../interfaces/report-model'
import { reportApi } from '../api/public/reports'
import { UserStore } from './user'
import { saveBlobResponseAsFile } from '@/utils/file'
import { checkApiError } from '../api/error-handlers'
import NProgress from 'nprogress'
import { showSuccessNotification } from '@/utils/notification'
import { ExamStore } from './exam'

export interface IReportState {
  data: IReport[]
  current: IReport | null
}

@Module({ dynamic: true, store, name: 'report', namespaced: true })
class Report extends VuexModule implements IReportState {
  data: IReport[] = []
  current: IReport | null = null

  @Mutation
  RESET_STATE() {
    this.data = []
    this.current = null
  }

  @Action
  async actionDelete(id: number): Promise<boolean> {
    try {
      NProgress.start()
      await Promise.all([
        reportApi.delete(UserStore.token, id),
        await new Promise((resolve) => setTimeout(resolve, 500)),
      ])
      NProgress.done()
      ExamStore.DELETE_EXAM_REPORT(id)
      showSuccessNotification({ content: 'Referto rimosso correttamente.' })
      return true
    } catch (error) {
      NProgress.done()
      checkApiError(error)
      return false
    }
  }

  @Action
  async actionDownload(id: number): Promise<void> {
    try {
      NProgress.start()
      const response = await reportApi.download(UserStore.token, id)
      NProgress.inc()
      saveBlobResponseAsFile(response, 'report.pdf')
      NProgress.done()
    } catch (error) {
      NProgress.done()
      checkApiError(error)
    }
  }
}

export const ReportStore = getModule(Report)
