import store from '@/store'
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'

export interface IAppState {
  isLoading: boolean
  isAboutActive: boolean
}

@Module({ dynamic: true, store, name: 'app', namespaced: true })
class App extends VuexModule implements IAppState {
  isLoading = false
  isAboutActive = false

  @Mutation
  RESET_STATE() {
    this.isLoading = false
    this.isAboutActive = false
  }

  @Mutation
  SET_IS_LOADING(payload: boolean) {
    this.isLoading = payload
  }

  @Mutation
  SET_IS_ABOUT_ACTIVE(payload: boolean) {
    this.isAboutActive = payload
  }
}

export const AppStore = getModule(App)
