import store from '@/store'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import { IExamWithReports } from '../interfaces/exam-model'
import { examApi } from '../api/public/exams'
import { checkApiError } from '../api/error-handlers'
import { UserStore } from './user'

export interface IExamState {
  data: IExamWithReports[]
  current: IExamWithReports | null
}

@Module({ dynamic: true, store, name: 'exam', namespaced: true })
class Exam extends VuexModule implements IExamState {
  data: IExamWithReports[] = []
  current: IExamWithReports | null = null

  @Mutation
  RESET_STATE() {
    this.data = []
    this.current = null
  }

  @Mutation
  private SET_DATA(payload: IExamWithReports[]) {
    this.data = payload
  }

  @Mutation
  DELETE_EXAM_REPORT(id: number) {
    for (const exam of this.data) {
      for (const report of exam.reports) {
        if (report.id == id) {
          report.deletion_date = new Date().toISOString()
        }
      }
    }
  }

  @Action
  async actionGetAll(): Promise<boolean> {
    try {
      const response = await examApi.getAll(UserStore.token)
      if (response) {
        this.SET_DATA(response.data)
      }
      return true
    } catch (error) {
      checkApiError(error)
      return false
    }
  }
}

export const ExamStore = getModule(Exam)
