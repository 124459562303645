import router from '@/router'

export interface IRouteNames {
  home: string
  about: string
  login: string
}

const routeNames: Readonly<IRouteNames> = {
  home: 'home',
  about: 'about',
  login: 'login',
}

declare module 'vue/types/vue' {
  interface Vue {
    $routeNames: IRouteNames
    $getPath(routeName: string): string
  }
}

export function getPath(routeName: string) {
  return router.resolve({ name: routeName }).resolved.path
}

export default routeNames
