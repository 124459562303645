import Vue from 'vue'
import Vuex from 'vuex'

import { IAppState } from './modules/app'
import { IExamState } from './modules/exam'
import { IPrescriptionState } from './modules/prescription'
import { IReportState } from './modules/report'
import { IUserState } from './modules/user'

Vue.use(Vuex)

export interface IRootState {
  app: IAppState
  exam: IExamState
  prescription: IPrescriptionState
  report: IReportState
  user: IUserState
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<IRootState>({})
