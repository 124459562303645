import { AxiosRequestConfig } from 'axios'

export function authConfig(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  } as AxiosRequestConfig
}

export function blobConfig(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  } as AxiosRequestConfig
}
